/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  const Sage = {
    // All pages
    common: {
      init: function () {
        let didScroll;
        let lastScrollTop = 0;
        const delta = 5;
        const navbarHeight = $("#banner").outerHeight();
        $(window).scroll(function () {
          didScroll = true;
        });

        setInterval(function () {
          if (didScroll) {
            hasScrolled();
            didScroll = false;
          }
        }, 250);

        function hasScrolled() {
          const st = $(this).scrollTop();

          if (Math.abs(lastScrollTop - st) <= delta) {
            return;
          }

          if (st > lastScrollTop && st > navbarHeight) {
            $("#banner").removeClass("nav-down").addClass("nav-up");
            $(".navbar > button").addClass("collapsed");
            $("#navbarNavDropdown").removeClass("show");
          } else {
            if (st + $(window).height() < $(document).height()) {
              $("#banner").removeClass("nav-up").addClass("nav-down");
            }
          }

          lastScrollTop = st;
        }

        if ($("#mobileHeaderButtons").length > 0) {
          $mobileButtons = $("#mobileHeaderButtons");
          $mobileButtons.remove();
          $("#menu-primary_navigation > li:last-child").after($mobileButtons);
        }

        $(".frame video").on("ended", function () {
          this.load();
          this.play();
        });

        $(function () {
          $("#search-filter-form-2810").on("input", function () {
            $(this).find("ul").addClass("no-background");
          });
        });

        const acc = document.getElementsByClassName("accordion");
        const panel = document.getElementsByClassName("panel");

        $('[data-toggle="tooltip"]').tooltip();

        // pricing tabs
        if ($(".pricing-plans").length > 0) {
          $(".tabbable .nav .nav-link").click(function () {
            const blocksTab = $(".nav-link");
            for (let index = 0; index < blocksTab.length; index++) {
              const block = blocksTab[index];

              if (
                $(this)[0] &&
                $(this)[0].id == block.id &&
                block.classList.contains("active") == false
              ) {
                block.classList.add("active");
              } else {
                block.classList.remove("active");
              }
            }

            const tabPanels = $(".tab-pane");
            for (index = 0; index < tabPanels.length; index++) {
              const block = tabPanels[index];
              const tabItem = $(this).attr("data-itm");

              if (
                block.id == tabItem &&
                block.classList.contains("active") == false
              ) {
                $(block).addClass("active");
                $(block).removeClass("fade in");
              } else if (block.classList.contains("active") == true) {
                $(block).removeClass("active");
                $(block).addClass("fade in");
              }
            }
          });
        }

        // countdown
        if ($(".countdown").length > 0) {
          const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

          const birthday = document.getElementById("count-to").dataset.cd,
            countDown = new Date(birthday).getTime(),
            x = setInterval(function () {
              const now = new Date().getTime(),
                distance = countDown - now;

              (document.getElementById("days").innerText = Math.floor(
                distance / day
              )),
                (document.getElementById("hours").innerText = Math.floor(
                  (distance % day) / hour
                )),
                (document.getElementById("minutes").innerText = Math.floor(
                  (distance % hour) / minute
                )),
                (document.getElementById("seconds").innerText = Math.floor(
                  (distance % minute) / second
                ));

              if (distance < 0) {
                $(".clock").hide();
                $(".headline").hide();
                $(".message").show();
                clearInterval(x);
              }
            }, 0);
        }

        //calculator

        //format input
        $("input[data-type='number']").keyup(function (event) {
          // skip for arrow keys
          if (event.which >= 37 && event.which <= 40) {
            event.preventDefault();
          }
          const $this = $(this);
          const num = $this.val().replace(/,/gi, "");
          const num2 = num.split(/(?=(?:\d{3})+$)/).join(",");

          $this.val(num2);
        });

        //cals
        function get_n_not(E, z) {
          return 0.25 * Math.pow(parseFloat(z) / (parseFloat(E) / 100.0), 2);
        }

        function get_fpc(N, E, z) {
          const n_not = get_n_not(E, z);
          return parseInt(Math.ceil((N * n_not) / (n_not + N - 1)));
        }

        $(".btn-calculate").click(function (e) {
          e.preventDefault();
          const errors = [];

          const population = parseFloat(
            $("#population").val().replace(/,/g, "") || 0
          );
          const confidence_level = $("input[name=conf-l]:checked").val();

          let confidence_level_val = null;

          const margin_error = parseFloat(
            $("#margin_error").val().replace(/,/g, "") || 0
          );

          if (confidence_level.length >= 0) {
            confidence_level_val = parseFloat(confidence_level || 0);
          } else {
            errors.push("confidence");
          }

          if (!margin_error || margin_error < 0 || margin_error > 100) {
            errors.push("margin");
          }

          if (!population) {
            errors.push("population");
          }

          if (errors.length) {
            $(".sample-size span").text("");
            $(".calc-error").text("Please enter the values correctly.");
          } else {
            const sample = get_fpc(
              population,
              margin_error,
              confidence_level_val
            );
            $(".sample-size span").text(sample);
            $(".calc-error").text("");
            $(".calculator-res").fadeIn();
          }
        });

        $('[data-toggle="tooltip"]').tooltip();

        ///stop youtube when close modal
        $(".modal").each(function () {
          $(this).on("click", function () {
            const myPlayer = $(this)
              .find("iframe")
              .attr("id", "youtube-iframe");
            let videoURL = myPlayer.prop("src");
            videoURL = videoURL.replace("&autoplay=0", "");
            $(document).on(
              "click",
              '[data-dismiss="modal"], .modal ',
              function (event) {
                myPlayer.prop("src", videoURL);
              }
            );
          });
        });

        for (let i = 0; i < acc.length; i++) {
          acc[i].onclick = function () {
            const setClasses = !this.classList.contains("active");
            setClass(acc, "active", "remove");
            setClass(panel, "show", "remove");

            if (setClasses) {
              this.classList.toggle("active");
              this.nextElementSibling.classList.toggle("show");
            }
          };
        }

        function setClass(els, className, fnName) {
          for (let i = 0; i < els.length; i++) {
            els[i].classList[fnName](className);
          }
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
    faqs: {
      init: function () {
        //faq page scrolling accordions sidebar
        const postDetails = document.querySelector(".accordion-with-sidebar");
        const postSidebar = document.querySelector(".accSidebar");

        const controller = new ScrollMagic.Controller();

        const scene = new ScrollMagic.Scene({
          triggerElement: postSidebar,
          triggerHook: 0,
          duration: getDuration,
        }).addTo(controller);

        if (window.matchMedia("(min-width: 768px)").matches) {
          scene.setPin(postSidebar, {
            pushFollowers: false,
          });
        }

        window.addEventListener("resize", function () {
          if (window.matchMedia("(min-width: 768px)").matches) {
            if ($(postSidebar)[0].style.position == "fixed") {
              console.log("element already sticky");
            } else {
              scene.setPin(postSidebar, {
                pushFollowers: false,
              });
            }
          } else {
            scene.removePin(postSidebar, true);
          }
        });

        function getDuration() {
          return postDetails.offsetHeight - postSidebar.offsetHeight - 110;
        }
      },
    },
    careers: {
      init: function () {
        // gallery
        lightbox.option({
          resizeDuration: 150,
          wrapAround: true,
          fitImagesInViewport: true,
          disableScrolling: true,
          showImageNumberLabel: false,
        });
      },
    },
  };

  // nav buttons for smaller screens

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  const UTIL = {
    fire: function (func, funcname, args) {
      let fire;
      const namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  $(document).on("click", '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
